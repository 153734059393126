import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from "@elastic/eui";
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Recording/Photoseries/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import PhotoTable from 'components/Web_User_Interface/1440p_Series/Recording/Photoseries/photoTable';
import PhotoScheduleTable from 'components/Web_User_Interface/1440p_Series/Recording/Photoseries/photoSheduleTable';
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "Recording Menu // Photoseries",
  "path": "/Web_User_Interface/1440p_Series/Recording/Photoseries/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Take snapshots at an adjustable time interval and merge them in third-party software to create a TimeLapse video. You can also upload the snapshots with a fixed file name. This will always overwrite the last file and allow you to display a live image from your camera on your website.",
  "image": "./WebUI_1440p_SearchThumb_Tasks_Photoseries.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title="Recording Menu // Photoseries" dateChanged="2021-12-12" author="Mike Polinowski" tag="INSTAR IP Camera" description="Take snapshots at an adjustable time interval and merge them in third-party software to create a TimeLapse video. You can also upload the snapshots with a fixed file name. This will always overwrite the last file and allow you to display a live image from your camera on your website." image="/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.png" twitter="/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Web_User_Interface/1440p_Serie/Aufnahme_Aufgaben/Fotoserie/" locationFR="/fr/Web_User_Interface/1440p_Series/Recording/Photoseries/" crumbLabel="Photoseries" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "task-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#task-menu",
        "aria-label": "task menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Task Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "configure-a-photo-series-on-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configure-a-photo-series-on-sd-card",
        "aria-label": "configure a photo series on sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure a Photo series on SD Card`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7bdd0ab78e5b192371c6566eaff8c239/86a1e/1440p_Settings_Tasks_Photoseries.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACqklEQVQ4y6WTS28TVxiG/SeQIqTYTlziuRgnIQYnxJ6bPUlz8WXOjGfGAdQKqElDUIIQIJIgtQuQuinrCupe2LApUiU2SFW7aX/Xg+Y4kxi6AbF4dL6Z0XnO+51zJqPrOpqmoek656bzzNXWaD98ycr+kNX9IY07P+OM4e4N6Tz4Hb3a5OzEGRaXlqhWq0xMTJDL5cikQlXVmDn3BWZzk/7OY4LBI8LtQ7Z2v6N365De9hH+4IBgcEB/54ia9SXzcwssLdVZXFxmYeES5fNzZGQ6TUNRFIpFBcdy6Ik2frdF4LXYinw5ivaGxGuvS7pehGOvUa/ZGHUH23KpLFRHCVVVlRSLRRzbJYpjemGIJ3y6nocnBL4f4Ac9OSbfwuAGq41NHNvBNBwpTVJmRu0mQmUkdJrEcUwYhggh3sP3fTnGccRO7ypXRIcV18QyGxj1xkj4YUK32aDfj4miSAq8JKHnydoPAnwh6Ecx398O2R24bK6b1JctLKNB5VSonQgbTvMkXSISwpN14At6oosXCLwo5snjbb792sJ1TCzDomnbXKxcHG/5WGifCtMWW60WbS+ktXWLqy3B9bUNXv34hKe7W1jGMiuOhduwqSTC05ZHp2yZDu1Wm26nw/r6Bp2Ox+DGNfYeHLH/9Cd+uHufZze/4e9ffuWPw3usOjWZzraSQxlLmFybhOXLdYy6hWnYrLkuj/Z3ePXiGS+Gz/n3v794+/o3/nw55J83r3l+sMdXbZNazZBtX7hQ+X/LhUJBMj1dQCnOMFtSmS3pqIpKuVymXNKZPV9ifn4OXVNRZgpjc6bJpOlSYT6fPyH5lSazCdlRPZmVtWRykmw2Ry6Xf29ORjlOlwqnpqY+i0y6d+nFTl6Or5g+f7RQ00skqFoJRVHlPqR8qiwRvgMTHQVsGwOQxAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7bdd0ab78e5b192371c6566eaff8c239/e4706/1440p_Settings_Tasks_Photoseries.avif 230w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/d1af7/1440p_Settings_Tasks_Photoseries.avif 460w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/7f308/1440p_Settings_Tasks_Photoseries.avif 920w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/6f791/1440p_Settings_Tasks_Photoseries.avif 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7bdd0ab78e5b192371c6566eaff8c239/a0b58/1440p_Settings_Tasks_Photoseries.webp 230w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/bc10c/1440p_Settings_Tasks_Photoseries.webp 460w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/966d8/1440p_Settings_Tasks_Photoseries.webp 920w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/0b716/1440p_Settings_Tasks_Photoseries.webp 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7bdd0ab78e5b192371c6566eaff8c239/81c8e/1440p_Settings_Tasks_Photoseries.png 230w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/08a84/1440p_Settings_Tasks_Photoseries.png 460w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/c0255/1440p_Settings_Tasks_Photoseries.png 920w", "/en/static/7bdd0ab78e5b192371c6566eaff8c239/86a1e/1440p_Settings_Tasks_Photoseries.png 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7bdd0ab78e5b192371c6566eaff8c239/c0255/1440p_Settings_Tasks_Photoseries.png",
              "alt": "Web User Interface - 1440p Series - Recording Email",
              "title": "Web User Interface - 1440p Series - Recording Email",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PhotoTable mdxType="PhotoTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c57f909329189bf53b944eb9b53e4669/86a1e/1440p_Settings_Tasks_Photoseries_Schedule.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC6UlEQVQ4y6WT22/TZhiH809MqiY1SRtofQhpS8PSLCfbiUvSJs6hiZMsK6VtstDWIWWH0g62CQ26IQZoTOIGCNXYxbgAaRJXSGw326Rxg/iLnsnOvHbbzaZdPPpeW/bz/d73sz2yLCNJEpIsc3zcz3Q8R/Hid5zefkj2wgF6/yHa+QFq7wFa7wF6f0Bx51vkSIY3R95gLholEokwMjKCz+fD4wpFUWLi+DFSmUXM7h6ltY+otHcpt/ccmtYVqt3LVLuXaG5+SlzJMjM9SzSaYG4uxuzsW4ROTONx0jlCgcnJSdJaho3uOlvn2my2l+l1V7A6Z2hVC1QL85QXNEoLaUolE03NkYirJBMaqqITno0ME4qi6OAI0/Osrq6ystZhbfcb1i/d5ezuHer9GzT612leuEnd+hyz2iar5dFUjVRSc6R2Ss8wnXiYMK3TqNdYaq7QuPErjVsvad78jdbXr2jdfknrzmua+8/ZqL1Lq2Iwr6dQUmmSifRQ+PeEeiZDw6xRNZuY1jXM3heY1j5m78thff469XOfcWXLxHpPJ7+QIhFTUJJpwodC6bBlNU2+uETeKFEu5BwqxgKVQpZKXqdoZCmUilz9pMvGWQVdS6EkFTKqyqnwqaMtu8IMF9sltpZLlJdqVJaqGEaRQrnOYsti2ajRyS7y+NY19q13UJIx5jUFPa0StoWHLdszFFBSGjurBaxlg2xukWKpTHf9DP0P99i+epf993f4qtPhx8EBTy5/wGkt7qRTFftQjiQUBMEh9naCSFQlFlfJ6Tp725t8f/829wf3+PmXFzx/esAPjwb89Owp9z7us2KkiMeTTtsnT4b/2XIgEOBYIOCswuQEU0GRqaCMKIiEQiFCQZmpE0FmZqaRJRFhYviszfj4OB43nSv0+/1/Yv9Ko14b77Ae9Tq1w+goXq8Pn8//l3c8wh/pXOHY2Nj/wuPOzv2w7ZtHd3Sv/7VQkoPYiFIQQRCdObj8V5kt/B0ItxCplqariwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c57f909329189bf53b944eb9b53e4669/e4706/1440p_Settings_Tasks_Photoseries_Schedule.avif 230w", "/en/static/c57f909329189bf53b944eb9b53e4669/d1af7/1440p_Settings_Tasks_Photoseries_Schedule.avif 460w", "/en/static/c57f909329189bf53b944eb9b53e4669/7f308/1440p_Settings_Tasks_Photoseries_Schedule.avif 920w", "/en/static/c57f909329189bf53b944eb9b53e4669/6f791/1440p_Settings_Tasks_Photoseries_Schedule.avif 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c57f909329189bf53b944eb9b53e4669/a0b58/1440p_Settings_Tasks_Photoseries_Schedule.webp 230w", "/en/static/c57f909329189bf53b944eb9b53e4669/bc10c/1440p_Settings_Tasks_Photoseries_Schedule.webp 460w", "/en/static/c57f909329189bf53b944eb9b53e4669/966d8/1440p_Settings_Tasks_Photoseries_Schedule.webp 920w", "/en/static/c57f909329189bf53b944eb9b53e4669/0b716/1440p_Settings_Tasks_Photoseries_Schedule.webp 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c57f909329189bf53b944eb9b53e4669/81c8e/1440p_Settings_Tasks_Photoseries_Schedule.png 230w", "/en/static/c57f909329189bf53b944eb9b53e4669/08a84/1440p_Settings_Tasks_Photoseries_Schedule.png 460w", "/en/static/c57f909329189bf53b944eb9b53e4669/c0255/1440p_Settings_Tasks_Photoseries_Schedule.png 920w", "/en/static/c57f909329189bf53b944eb9b53e4669/86a1e/1440p_Settings_Tasks_Photoseries_Schedule.png 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c57f909329189bf53b944eb9b53e4669/c0255/1440p_Settings_Tasks_Photoseries_Schedule.png",
              "alt": "Web User Interface - 1440p Series - Recording Email",
              "title": "Web User Interface - 1440p Series - Recording Email",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PhotoScheduleTable mdxType="PhotoScheduleTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      